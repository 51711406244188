import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'

import {
    BRANDS,
    DATES,
    DSP_ADVERTISERS,
    DSP_AUDIENCE_ID,
    DSP_AUDIENCE_NAME,
    DSP_CREATIVE_ID,
    DSP_CREATIVE_NAME,
    DSP_CREATIVE_TYPE,
    DSP_LINE_ITEMS,
    DSP_ORDERS,
    LABELS,
} from 'const/filters'
import { DSP_ORDER_LABELS } from 'const/resourceTypes'
import { formatCerebroDate } from 'helpers/formatting'

import { calculateFromDateWithLag, presetRanges } from '../dateRange'
import { formatBooleanFilter } from '../filters'
import { isNonEmptyArray } from '../typeGuard'

export const formatFilters = (filters) => {
    const params = {}

    if (!isEmpty(filters[DATES])) {
        if (isArray(filters[DATES])) {
            params.report_date_min = formatCerebroDate(filters[DATES][0])
            params.report_date_max = formatCerebroDate(filters[DATES][1])
        } else {
            const { range } = presetRanges(
                calculateFromDateWithLag(filters.rangeLag),
                filters.rangeLag
            )[filters[DATES]]

            params.report_date_min = formatCerebroDate(range[0])
            params.report_date_max = formatCerebroDate(range[1])
        }
    }

    if (!isEmpty(filters[BRANDS])) {
        params.profile_id__in = filters[BRANDS].map((item) => item.value).join()
    }

    if (!isEmpty(filters[DSP_ADVERTISERS])) {
        params.advertiser_id__in = filters[DSP_ADVERTISERS].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[DSP_ORDERS])) {
        params.order_id__in = filters[DSP_ORDERS].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[DSP_LINE_ITEMS])) {
        params.line_item_id__in = filters[DSP_LINE_ITEMS].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[DSP_AUDIENCE_NAME])) {
        params.segment_name__in = filters[DSP_AUDIENCE_NAME].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[DSP_AUDIENCE_ID])) {
        params.segment_id__in = filters[DSP_AUDIENCE_ID].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[DSP_CREATIVE_NAME])) {
        params.creative_name__in = filters[DSP_CREATIVE_NAME].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[DSP_CREATIVE_ID])) {
        params.creative_id__in = filters[DSP_CREATIVE_ID].map(
            (item) => item.value
        ).join()
    }

    const labels = filters[LABELS]
    if (isNonEmptyArray(labels)) {
        params.complex_label_filter = formatBooleanFilter(
            labels,
            DSP_ORDER_LABELS
        )
    }

    const dspCreativeType = filters[DSP_CREATIVE_TYPE]
    if (isNonEmptyArray(dspCreativeType)) {
        const payload = { or: [] }
        dspCreativeType.forEach((item) => {
            if (item.value === 'unknown') {
                payload.or.push({ creative_type__isnull: true })
            } else {
                payload.or.push({
                    creative_type__icontains: item?.value,
                })
            }
        })
        params.boolean_filter = JSON.stringify(payload)
    }

    return params
}
